<template>
  <div class="bg-img padding-90 min-height">
    <div class="width-100 padding-90 br-mid margin-b bg-white">
      <div class="width-100 margin-bs flex-row justify-content-spaceBetween align-items-center">
        <div class="width-40 title">
          <p class="">未成年人信息</p>
        </div>
      </div>
      <div class="width-100 margin-b flex-row justify-content-start align-items-center">
        <p class="fs-mid width-20 darkgrey">{{ data.childName }}</p>
        <p class="fs-mid width-10 darkgrey" v-if="data.childGender == '1'">男</p>
        <p class="fs-mid width-10 darkgrey" v-if="data.childGender == '2'">女</p>
        <p class="fs-mid width-20 darkgrey">{{ data.childAge }}岁</p>
      </div>
      <div class="width-100 margin-bs flex-row justify-content-spaceBetween align-items-center">
        <div class="width-40 title">
          <p class="">侵害人信息</p>
        </div>
      </div>
      <div class="width-100 margin-b flex-row justify-content-start align-items-center">
        <p class="fs-mid width-20 darkgrey">{{ data.perpetratorName }}</p>
        <p class="fs-mid width-10 darkgrey" v-if="data.childGender == '1'">男</p>
        <p class="fs-mid width-10 darkgrey" v-if="data.perpetratorGender == '2'">女</p>
        <p class="fs-mid width-20 darkgrey"></p>
      </div>
      <div class="width-100 margin-bs flex-row justify-content-spaceBetween align-items-center">
        <div class="width-40 title">
          <p class="">事件信息</p>
        </div>
      </div>
      <div class="width-100 margin-bs margin-ts flex-row justify-content-start align-items-center">
        <img src="@/assets/image/3.png" height="15rem" alt="" />
        <p class="fs-mid darkgrey margin-ls">{{ data.happenedTime }}</p>
      </div>
      <div class="width-100 margin-bs flex-row justify-content-start align-items-star">
        <img src="@/assets/image/4.png" height="15rem" alt="" />
        <p class="fs-mid darkgrey margin-ls">{{ data.reportLocation }}</p>
      </div>
      <p class="fs-mid margin-b darkgrey line-height">{{ data.reportContent }}</p>
      <div class="width-100 margin-bs flex-row justify-content-spaceBetween align-items-center" v-if="data.reportImg">
        <div class="width-40 title">
          <p class="">附件图片</p>
        </div>
      </div>
      <div class="width-100 flex-row justify-content-spaceBetween align-items-star flex-wrap" v-if="data.reportImg">
        <van-image class="margin-bs" width="31%" height="5rem" fit="contain" v-for="item in imageCut(data.reportImg)" :key="item" :src="item" />
      </div>
    </div>
    <div class="width-100 padding-90 br-mid bg-white" v-if="data.reportStatus == '1'">
      <div class="width-100 margin-bs flex-row justify-content-spaceBetween align-items-center">
        <div class="width-40 title">
          <p class="">回复信息</p>
        </div>
      </div>
      <p class="fs-mid margin-b darkgrey line-height">待回复</p>
    </div>
    <div class="width-100 padding-90 br-mid bg-white" v-else-if="data.reportStatus == '2'">
      <div class="width-100 margin-bs flex-row justify-content-spaceBetween align-items-center">
        <div class="width-40 title">
          <p class="">回复信息</p>
        </div>
        <p class="width-60 textAlign-r grey">{{ data.updateTime }}</p>
      </div>
      <p class="fs-mid margin-b darkgrey line-height">{{ data.reportReply }}</p>
    </div>
    <div class="width-100 padding-90 br-mid bg-white" v-else>
      <div class="width-100 margin-bs flex-row justify-content-spaceBetween align-items-center">
        <div class="width-40 title">
          <p class="">回复信息</p>
        </div>
      </div>
      <p class="fs-mid margin-b darkgrey line-height">未回复</p>
    </div>
  </div>
</template>
<script>
import { clueReportShow } from '@/api/reportApi';

export default {
  name: 'clueReportInfo',
  data() {
    return {
      data: {}
    };
  },
  created() {
    let id = this.$route.query.id;

    this.initData(id);
  },
  methods: {
    initData(id) {
      clueReportShow(id).then((response) => {
        this.data = response.data;
      });
    },
    imageCut(image) {
      let images = image.split(',');
      return images;
    }
  }
};
</script>

<style lang="less" scoped>
.bg-img {
  background: url('../../assets/image/bg4.png') round no-repeat;
  background-size: contain;
  background-color: rgb(237, 237, 237);
}
.title {
  line-height: 2rem;
  background: url('../../assets/image/5.png') left bottom no-repeat;
  background-size: contain;
  p {
    //color: rgb(29,49,113);
    font-size: 1.2rem;
  }
}
</style>
